window.wordCount = {
    text: '',
    charCount: 0,
    maxChars: 80,
    updateCharCount() {
        this.charCount = this.text.length;
        if (this.charCount > this.maxChars) {
            this.text = this.text.slice(0, this.maxChars);
            this.charCount = this.maxChars;
        }
    },
    init() {
        this.updateCharCount();
    }
};