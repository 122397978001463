class Wysiwyg {
    constructor({data}){
        this.data = data;
    }

    static get toolbox() {
        return {
            title: 'Text Editor',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>',
        };
    }

    render(editorId) {
        // Create the main outer div as a flex container
        const outerDiv = document.createElement('div');
        outerDiv.className = 'w-full rounded-lg bg-gray-800 border-none box-border my-4';

        // Create a unique hidden input element to hold the editor data
        const inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.id = `trix-content-${Math.random().toString(36).slice(2, 9)}`;
        inputElement.value = this.data && this.data.content ? this.data.content : '';

        // Create the Trix editor element
        const editorElement = document.createElement('trix-editor');
        editorElement.setAttribute('input', inputElement.id);
        editorElement.style.height = '300px';

        // Event listener to trap Enter key inside the editor
        editorElement.addEventListener('keydown', function(event) {
            if (event.key === 'Enter' || event.key === 'Backspace') {
                event.stopPropagation();
            }
        });

        // Append the input and editor elements to the main div
        outerDiv.appendChild(inputElement);
        outerDiv.appendChild(editorElement);

        return outerDiv;
    }

    save(blockContent) {
        const editor = blockContent.querySelector('[id^="trix-content"]');

        return {
            content: editor ? editor.value : null
        }
    }
}

export default Wysiwyg;