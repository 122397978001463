window.tagSelect = () => {
    return {
        open: false,
        textInput: '',
        tags: [],
        promoted: [],
        options: [],
        init() {
            const parseAttribute = (attrName, defaultValue = []) => {
                try {
                    return JSON.parse(this.$el.parentNode.getAttribute(attrName))
                } catch (error) {
                    return defaultValue;
                }
            };

            this.tags = parseAttribute('data-tags')
            this.promoted = parseAttribute('data-promoted')
            this.options = parseAttribute('data-options')
        },
        addTag(tag) {
            tag = tag.trim()
            if (tag != "" && !this.hasTag(tag)) {
                this.tags.push( tag )
            }
            this.clearSearch()
            this.$refs.textInput.focus()
            this.fireTagsUpdateEvent()
        },
        fireTagsUpdateEvent() {
            this.$el.dispatchEvent(new CustomEvent('tags-update', {
                detail: { tags: this.tags },
                bubbles: true,
            }));
        },
        hasTag(tag) {
            var tag = this.tags.find(e => {
                return e.toLowerCase() === tag.toLowerCase()
            })
            return tag != undefined
        },
        removeTag(index) {
            this.tags.splice(index, 1)
            this.fireTagsUpdateEvent()
        },
        search(q) {
            if ( q.includes(",") ) {
                q.split(",").forEach(function(val) {
                    this.addTag(val)
                }, this)
            }
            this.toggleSearch()
        },
        filteredOptions () {
            return this.options.filter(option => {
                return option.toLowerCase().includes(this.textInput.toLowerCase())
            }).slice(0, 10)
        },
        clearSearch() {
            this.textInput = ''
            this.toggleSearch()
        },
        toggleSearch() {
            this.open = this.textInput != ''
        },
        togglePromoted(tag) {
            if (this.promoted.includes(tag)) {
                this.promoted.splice(this.promoted.indexOf(tag), 1)
            } else {
                if (this.promoted.length >= 3) {
                    this.promoted.shift()
                }

                this.promoted.push(tag)
            }
        }
    }
}