import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import RawTool from '@editorjs/raw';
import Undo from 'editorjs-undo';
import Blog from './editor/blog';
import Contact from './editor/contact';
import Wysiwyg from "./editor/wysiwyg";
import Hero1 from "./editor/hero1";

window.editorReady = false;

(function(){
    const foundEditor = document.getElementById('editorjs');

    if (foundEditor) {
        window.editor = new EditorJS({
            holder: 'editorjs',
            tools: {
                header: Header,
                image: {
                    class: ImageTool,
                    config: {
                        additionalRequestHeaders: {
                            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        },
                        endpoints: {
                            byFile: '/image', // Your backend file uploader endpoint
                            byUrl: '/fetchUrl', // Your endpoint that provides uploading by Url
                        }
                    }
                },
                wysiwyg: Wysiwyg,
                list: List,
                raw: RawTool,
                blog: Blog,
                contact: Contact,
                hero1: Hero1
            },
            onReady: () => {
                window.editorReady = true
                new Undo({ editor });
            },
            placeholder: 'Add your content here...',
            autofocus: true,
            data: {
            },
        })

        setTimeout(() => {
            document.querySelectorAll('[contenteditable="true"]').forEach(element => {
                element.addEventListener('keydown', function(event) {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        event.stopPropagation();

                        const selection = window.getSelection();
                        if (selection.rangeCount > 0) {
                            const range = selection.getRangeAt(0);
                            range.deleteContents(); // Remove selected content

                            // Insert a new line
                            const br = document.createElement('br');
                            range.insertNode(br);

                            // Move the cursor to the next line
                            range.setStartAfter(br);
                            range.setEndAfter(br);
                            selection.removeAllRanges();
                            selection.addRange(range);

                            // Insert a second BR if at the end of the line to ensure the next line
                            if (element.lastChild === br) {
                                element.appendChild(document.createElement('br'));
                            }
                        }

                        return false;           // Stop further handling
                    }
                });
            });

        }, 1000)
    }
})();