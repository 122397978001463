class Contact {
    constructor({data}){
        this.data = data;
    }

    static get toolbox() {
        return {
            title: 'Contact',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>',
        };
    }

    render() {
        // Create the main outer div as a flex container
        const outerDiv = document.createElement('div');
        outerDiv.className = 'flex w-full rounded-lg bg-gray-800 text-white p-4 border-none box-border my-4 space-x-4';

        // Create the left column div for the Trix editor
        const leftCol = document.createElement('div');
        leftCol.className = 'flex-1'; // Adjust flex value to suit layout

        // Create a hidden input element to hold the editor data
        const inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.id = `trix-content-${Math.random().toString(36).slice(2, 9)}`;
        inputElement.value = this.data && this.data.content ? this.data.content : '';

        // Create the Trix editor element
        const editorElement = document.createElement('trix-editor');
        editorElement.setAttribute('input', inputElement.id);
        editorElement.style.height = '300px';

        // Event listener to trap Enter key inside the editor
        editorElement.addEventListener('keydown', function(event) {
            if (event.key === 'Enter' || event.key === 'Backspace') {
                event.stopPropagation();
            }
        });

        // Append the input and editor elements to the left column
        leftCol.appendChild(inputElement);
        leftCol.appendChild(editorElement);

        // Create the right column div for the contact form placeholder
        const rightCol = document.createElement('div');
        rightCol.className = 'flex-1 flex items-center justify-center bg-gray-700 p-4 rounded-lg';

        // Add centered text for the contact form placeholder
        const formPlaceholder = document.createElement('div');
        formPlaceholder.className = 'text-center';
        formPlaceholder.innerText = 'This will generate a contact form';

        // Append the placeholder to the right column
        rightCol.appendChild(formPlaceholder);

        // Append both columns to the main outer div
        outerDiv.appendChild(leftCol);
        outerDiv.appendChild(rightCol);

        return outerDiv;
    }

    save(blockContent) {
        const editor = blockContent.querySelector('[id^="trix-content"]');

        return {
            content: editor ? editor.value : null
        }
    }
}

export default Contact;