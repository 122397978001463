window.servicesOffered = function () {
    return {
        services: [],
        max_services: 0,
        addService: function () {
            if (this.services.length < this.max_services) {
                this.services.push("")
            }
        },
        deleteService: function (index) {
            this.services.splice(index, 1)
        },
        addButtonVisible: function () {
            return this.services.length < this.max_services
        }
    }
}

window.servicesPricing = function () {
    return {
        services: [],
        max_services: 0,
        addService: function (service) {
            if (this.services.length < this.max_services) {
                this.services.push({
                    title: "",
                    description: "",
                    price: "",
                    gap: ""
                })
            }
        },
        deleteService: function (index) {
            this.services.splice(index, 1)
        },
        addButtonVisible: function () {
            return this.services.length < this.max_services
        }
    }
}