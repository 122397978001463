window.menuDropdown = () => {
    return {
        dropdownOpen: false,
        init() {
            console.log("Menu dropdown initialized")
        },
        checkMouseOut(event) {
            const targetElement = event.currentTarget;
            const relatedElement = event.relatedTarget;

            // Check if the related element is null or not inside the target element
            if (!relatedElement || !targetElement.contains(relatedElement)) {
                // The mouse has moved outside the target element
                this.dropdownOpen = false;
            }
        }
    }
}