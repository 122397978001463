window.dropdown = function() {
    return {
        isOpen: false,
        query: '',
        selectedPerson: null,
        selectedId: null,
        people: [],
        filteredPeople: [],
        checkUser: false,

        init() {
            const list = this.$el.getAttribute('data-list');
            this.people = JSON.parse(decodeURIComponent(list));

            this.filteredPeople = this.people;

            this.selectedPerson = this.people.find((person) => person.id === this.selectedId);
            if (this.selectedPerson) {
                this.query = this.selectedPerson.name;
            }

            // Select the first result when tabbing out of the search field
            this.$el.addEventListener('keydown', (event) => {
                console.log(event.key)
                if (event.key === 'Tab') {
                    this.selectPerson(this.filteredPeople[0]);
                }
            });

            window.addEventListener('clear-dropdown', (event) => {
                this.clearFunction(event);
            });

            // on blur, select the first result if the user has typed something
            /*this.$el.addEventListener('focusout', (event) => {
                if (this.query !== '') {
                    //this.selectPerson(this.filteredPeople[0]);
                }
            });*/
        },
        clearFunction(event) {
            const target = event.detail.target;

            // check if this.$el has a child with the id of target
            if (this.$el.contains(document.getElementById(target))) {
                this.selectedPerson = null;
                this.selectedId = null;
                this.query = '';
            }
        },
        filterPeople() {
            if (this.query === '') {
                this.filteredPeople = this.people;
            } else {
                this.filteredPeople = this.people.filter((person) =>
                    person.name.toLowerCase().includes(this.query.toLowerCase())
                );
            }

            // Highlight the first result after each search update
            this.selectedPerson = this.filteredPeople[0];
        },
        selectPerson(person) {
            if (!person) {
                this.selectedPerson = null;
                this.selectedId = null;
                this.query = '';
                this.close();
                return;
            }

            this.selectedPerson = person;
            this.query = person.name;
            this.selectedId = person.id;
            this.close();

            console.log(this.selectedId)

            this.$nextTick(() => {
                if (this.checkUser) {
                    window.checkExistingTimesheet();
                }
            });
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        close() {
            this.isOpen = false;
        },
    };
}
