/* eslint no-console:0 */

import Alpine from 'alpinejs'

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "@shoelace-style/shoelace/dist/components/rating/rating.js"
import "@shoelace-style/shoelace/dist/components/select/select.js"
import "@shoelace-style/shoelace/dist/components/option/option.js"
import "@shoelace-style/shoelace/dist/components/divider/divider.js"
import "@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.js"
import "@shoelace-style/shoelace/dist/components/carousel/carousel.js"

import L from "leaflet";
window.L = L;

const { faker } = require('@faker-js/faker');
window.faker = faker

import "./channels"
import "./controllers"
import "./src/**/*"

window.Alpine = Alpine

// Always last
Alpine.start()