class Hero1 {
    // Random container ID to avoid conflicts
    container_id = 'hero1-' + Math.random().toString(36).substr(2, 9);

    constructor({data}) {
        this.data = data;

        // Tuning settings
        this.settings = [
            {
                name: 'flip',
                label: 'Flip',
                callback: 'btnFlip',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M256 0c-13.3 0-24 10.7-24 24l0 464c0 13.3 10.7 24 24 24s24-10.7 24-24l0-464c0-13.3-10.7-24-24-24zM492.2 98.4c-12-5-25.7-2.2-34.9 6.9l-128 128c-12.5 12.5-12.5 32.8 0 45.3l128 128c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-256c0-12.9-7.8-24.6-19.8-29.6zm-472.5 0C7.8 103.4 0 115.1 0 128L0 384c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9z"/></svg>`
            },
            {
                name: 'toggleBackground',
                label: 'Show/Hide Background',
                callback: 'btnToggleBackground',
                icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.043 8.265l3.183-3.183h-2.924L4.75 10.636v2.923l4.15-4.15v2.351l-2.158 2.159H8.9v2.137H4.7c-1.215 0-2.2-.936-2.2-2.09v-8.93c0-1.154.985-2.09 2.2-2.09h10.663l.033-.033.034.034c1.178.04 2.12.96 2.12 2.089v3.23H15.3V5.359l-2.906 2.906h-2.35zM7.951 5.082H4.75v3.201l3.201-3.2zm5.099 7.078v3.04h4.15v-3.04h-4.15zm-1.1-2.137h6.35c.635 0 1.15.489 1.15 1.092v5.13c0 .603-.515 1.092-1.15 1.092h-6.35c-.635 0-1.15-.489-1.15-1.092v-5.13c0-.603.515-1.092 1.15-1.092z"/></svg>`
            }
        ];
    }

    /**
     * Define the component toolbox
     * @returns {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            title: 'Hero1',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M192 32c0 17.7 14.3 32 32 32c123.7 0 224 100.3 224 224c0 17.7 14.3 32 32 32s32-14.3 32-32C512 128.9 383.1 0 224 0c-17.7 0-32 14.3-32 32zm0 96c0 17.7 14.3 32 32 32c70.7 0 128 57.3 128 128c0 17.7 14.3 32 32 32s32-14.3 32-32c0-106-86-192-192-192c-17.7 0-32 14.3-32 32zM96 144c0-26.5-21.5-48-48-48S0 117.5 0 144V368c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144H128v96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48s-48-21.5-48-48V144z"/></svg>',
        };
    }

    /**
     * Render the component HTML
     * @returns {HTMLDivElement}
     */
    render() {
        // Create the container element
        const container = document.createElement('div');

        container.innerHTML = `
            <div x-data="{
                    data: {
                        header: '${this.getDataValue('header', 'We are changing the way people connect.')}',
                        body: '${this.getDataValue('body', 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.')}',
                        isLeft: ${this.getDataValue('isLeft', false)},
                        buttons: ${this.getDataValue('buttons', [{text: 'Get started',url: '#',classes: 'rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}])},
                        image: ${this.getDataValue('image', {url: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',alt: ''})},
                        showBackground: ${this.getDataValue('showBackground', true)},
                    },
                    focusedButtonIndex: null,
                    focusedImageIndex: null,
                    blurTimeout: null,
                    addNewButton() {
                        this.data.buttons.push({text: 'New button', url: '#', classes: 'rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'});
                    },
                    uploadImage(event) {
                        const file = event.target.files[0];
                        if (!file) {
                            this.message = 'No file selected';
                            return;
                        }
                        
                        const formData = new FormData();
                        formData.append('image', file);
                        
                        fetch('/image', {
                            method: 'POST',
                            headers: {
                                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
                            },
                            body: formData,
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                this.data.image.url = data.file.url;
                            } else {
                                alert('Failed to upload image.');
                            }
                        })
                        .catch(error => {
                            alert('Error uploading image: error');
                        });
                    }
                }"
                x-on:update-data${this.container_id}.window="Object.assign($data.data, $event.detail)"
                x-on:request-data${this.container_id}.window="$dispatch('response-data${this.container_id}', $data.data)"
                id="${this.container_id}"
                class="relative isolate overflow-hidden pt-14"
                :class="{'bg-gradient-to-b from-indigo-100/20': data.showBackground}">
                

                <div x-show="data.showBackground"
                     class="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96" aria-hidden="true"></div>

                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div :class="{'lg:grid-cols-2-reverse': !data.isLeft}" class="mx-auto max-w-none lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-16 lg:gap-y-6 xl:gap-x-8">
                        <div class="lg:col-span-1">
                            <div @blur="data.header = $event.target.innerHTML"
                                 x-html="data['header']"
                                 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" contenteditable="true">
                            </div>
                            <div class="mt-6 max-w-xl lg:mt-0">
                                <div @blur="data.body = $event.target.innerHTML"
                                     x-html="data['body']"
                                     class="text-lg leading-8 text-gray-600" contenteditable="true"></div>
                                <div class="mt-10 flex flex-wrap items-center gap-x-6 gap-y-4">
                                    <template x-for="(button, index) in data.buttons" :key="index">
                                        <div class="relative">
                                          <span :href="button.url"
                                                :class="button.classes"
                                                class="inline-block"
                                                x-text="button.text"
                                                @focus="clearTimeout(blurTimeout); focusedButtonIndex = index"
                                                @blur="blurTimeout = setTimeout(() => { focusedButtonIndex = null; button.text = $event.target.innerText; }, 300)"
                                                contenteditable="true"></span>
                                    
                                          <div x-show="focusedButtonIndex === index" class="tooltip"
                                                style="z-index: 50; position: absolute; top: -110%; left: 100%; padding: 10px; background: white; border: 1px solid black;">
                                            <div>
                                                <label for="button-url" class="block text-sm font-medium text-gray-700">Button URL</label>
                                                <input type="text"
                                                       id="button-url"
                                                       x-model="button.url"
                                                       placeholder="Enter URL here"
                                                       @focus="clearTimeout(blurTimeout)"
                                                       @blur="blurTimeout = setTimeout(() => { focusedButtonIndex = null; }, 300)">
                                            </div>
                                            <div>
                                                <button @click="data.buttons.splice(index, 1)"
                                                        class="mt-2 px-3 py-1.5 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Delete</button>
                                            </div>
                                          </div>
                                        </div>
                                    </template>
                                    <div @click="addNewButton()">
                                        <svg class="w-5 h-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div :class="{'lg:col-start-1': data.isLeft, 'lg:col-start-2': !data.isLeft}"
                             class="relative lg:col-span-1 aspect-[6/5] w-full max-w-lg sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                             x-show="data.image.url != ''">
                            <img 
                                :src="data.image.url"
                                :alt="data.image.alt"
                                class="rounded-2xl object-cover object-center w-full h-full sm:rounded-3xl"
                                @mouseover="clearTimeout(blurTimeout); focusedImageIndex = 1"
                                @mouseout="blurTimeout = setTimeout(() => { focusedImageIndex = null; }, 300)">

                            <div x-show="focusedImageIndex === 1"
                                class="tooltip"
                                @mouseover="clearTimeout(blurTimeout); focusedImageIndex = 1"
                                @mouseout="blurTimeout = setTimeout(() => { focusedImageIndex = null; }, 300)"
                                style="z-index: 500; position: absolute; top: 0%; left: 0; padding: 10px; background: white; border: 1px solid black;">
                                <div>
                                    <input type="file" @change="uploadImage($event)" accept="image/*">
                                    <input type="text"
                                            x-model="data.image.alt"
                                            placeholder="Image alt text"
                                            class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                </div>
                                <div>
                                    <button @click="data.image.url = ''"
                                            class="mt-2 px-3 py-1.5 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Delete</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"></div>
            </div>
        `;

        return container;
    }

    /**
     * Render settings for the block
     * @returns {HTMLDivElement}
     */
    renderSettings(){
        const wrapper = document.createElement('div');
        wrapper.classList.add('ce-popover__items')

        this.settings.forEach( tune => {
            let button = document.createElement('div');
            button.classList.add('ce-popover-item')

            button.innerHTML = `
                <div class="ce-popover-item__icon">
                    ${tune.icon}
                </div>
                <div class="ce-popover-item" data-item-name="move-up">
                    ${tune.name}
                </div>`

            wrapper.appendChild(button);

            button.addEventListener('click', () => {
                this[tune.callback]();
            });

            //button.addEventListener('click', () => {
            //    this.updateComponentData({ header: 'Updated Header', body: 'Updated body' });
                //button.classList.toggle('cdx-settings-button--active');
            //});

        });

        return wrapper;
    }

    async btnFlip() {
        const currentValue = await this.getComponentData('isLeft', true);
        this.updateComponentData({ isLeft: !currentValue });
    }

    async btnToggleBackground() {
        const currentValue = await this.getComponentData('showBackground', true);
        this.updateComponentData({ showBackground: !currentValue });
    }

    /**
     * Send the data to the Alpine component data
     * @param data
     */
    updateComponentData(data) {
        window.dispatchEvent(new CustomEvent(`update-data${this.container_id}`, { detail: data }));
    }

    /**
     * Get the data from Alpine
     * @returns {Promise<unknown>}
     */
    fetchDataFromComponent() {
        return new Promise((resolve) => {
            // Add event listener to handle the response
            window.addEventListener(`response-data${this.container_id}`, function handler(event) {
                window.removeEventListener(`response-data${this.container_id}`, handler); // Remove handler after receiving response
                resolve(event.detail);
            }, { once: true });

            // Dispatch the request event
            window.dispatchEvent(new CustomEvent(`request-data${this.container_id}`));
        });
    }

    /**
     * Save the data
     * @param blockContent
     * @returns {Promise<{header, body}>}
     */
    async save(blockContent) {
        // Get the data from the Alpine component
        const data = await this.fetchDataFromComponent();
        console.log(data)
        return data;
    }

    getDataValue(key, val = '') {
        let data = this.data?.[key] ?? val;

        if (typeof data === 'object') {
            data = JSON.stringify(data);
            data = data.replaceAll('"', "'")
        } else if (typeof data === 'string') {
            data = data.replaceAll("'", "\\'")
        }
        return data
    }

    async getComponentData(key, val = '') {
        const data = await this.fetchDataFromComponent()
        return data[key] ?? val;
    }
}

export default Hero1;