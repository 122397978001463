window.log_stat = (event, listing_id) => {
    fetch(`/log_stat/${listing_id}/${event}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
            event: event,
            listing_id: listing_id
        })
    }).then(() => {}).catch(() => {});
}